import * as React from "react"
import Website from "@src/components/wrappers/website"
import Nav from "@src/components/marketing/headerAlt"
import Footer from "@components/marketing/footer"
import Button from "@src/components/marketing/button"

const seo = {
  url: "/404",
  title: "404 Page Not Found",
  description: "Sorry, we couldn't find the page you're looking for.",
  noindex: true
}

export default class FourZeroFour extends React.Component {
  render() {
    return (
      <Website seo={seo} marketing>
        <Nav />
        <div className="text-center px-6 py-10 sm:py-12 md:py-16">
          <h1 className="mx-auto text-gray-900 font-semibold leading-tight text-3xl max-w-lg sm:text-3xl sm:max-w-2xl md:text-4xl md:max-w-3xl lg:text-5xl lg:max-w-4xl">
            404 Not Found
    	</h1>
          <p className="text-gray-700 text-sm mt-2 mx-auto max-w-lg sm:text-lg sm:max-w-xl md:text-xl md:max-w-2xl mb-8">
            Oops! The page you're looking for does not exist.
    	</p>
          <Button medium link to="/">
            Go Home
      </Button>
        </div>
        <Footer />
      </Website>
    )
  }
}

